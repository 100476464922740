/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = OnTheFirstSameDayNumber  2 = OnTheFirstSameDayOfWeek  3 = OnTheSecondSameDayOfWeek  4 = OnTheThirdSameDayOfWeek  5 = OnTheFourthSameDayOfWeek  6 = OnTheLastSameDayOfWeek
 */
export const MonthlyRepeatOptionType = {
  Undefined: 0,
  OnTheFirstSameDayNumber: 1,
  OnTheFirstSameDayOfWeek: 2,
  OnTheSecondSameDayOfWeek: 3,
  OnTheThirdSameDayOfWeek: 4,
  OnTheFourthSameDayOfWeek: 5,
  OnTheLastSameDayOfWeek: 6,
} as const;

export type MonthlyRepeatOptionType =
  (typeof MonthlyRepeatOptionType)[keyof typeof MonthlyRepeatOptionType];

/**
 * @deprecated use EnumViewModel<MonthlyRepeatOptionType> instead.
 *
 * Maps a MonthlyRepeatOptionType enumeration value to its corresponding translation key.
 *
 * @param { MonthlyRepeatOptionType } monthlyRepeatOptionType -
 *        The enumeration value representing the type of MonthlyRepeatOptionType.
 * @returns {string} The translation key corresponding to the given MonthlyRepeatOptionType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('MonthlyRepeatOptionType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromMonthlyRepeatOptionType(MonthlyRepeatOptionType.OnTheFirstSameDayNumber);
 * console.log(translationKey); // 'MonthlyRepeatOptionType.OnTheFirstSameDayNumber'
 * console.log(translocoService.translate(translationKey)) // "Translation of MonthlyRepeatOptionType.OnTheFirstSameDayNumber"
 *
 * // using in a template:
 * {{ translationKeyFromMonthlyRepeatOptionType(MonthlyRepeatOptionType.OnTheFirstSameDayNumber) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('MonthlyRepeatOptionType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromMonthlyRepeatOptionType(
  monthlyRepeatOptionType: MonthlyRepeatOptionType,
) {
  switch (monthlyRepeatOptionType) {
    case MonthlyRepeatOptionType.Undefined:
      return 'MonthlyRepeatOptionType.Undefined';
    case MonthlyRepeatOptionType.OnTheFirstSameDayNumber:
      return 'MonthlyRepeatOptionType.OnTheFirstSameDayNumber';
    case MonthlyRepeatOptionType.OnTheFirstSameDayOfWeek:
      return 'MonthlyRepeatOptionType.OnTheFirstSameDayOfWeek';
    case MonthlyRepeatOptionType.OnTheSecondSameDayOfWeek:
      return 'MonthlyRepeatOptionType.OnTheSecondSameDayOfWeek';
    case MonthlyRepeatOptionType.OnTheThirdSameDayOfWeek:
      return 'MonthlyRepeatOptionType.OnTheThirdSameDayOfWeek';
    case MonthlyRepeatOptionType.OnTheFourthSameDayOfWeek:
      return 'MonthlyRepeatOptionType.OnTheFourthSameDayOfWeek';
    case MonthlyRepeatOptionType.OnTheLastSameDayOfWeek:
      return 'MonthlyRepeatOptionType.OnTheLastSameDayOfWeek';
    default:
      return '';
  }
}

/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Pickup  2 = Delivery  4 = Stop  8 = PickupContainer  16 = DropContainer
 */
export const StopType = {
  Undefined: 0,
  Pickup: 1,
  Delivery: 2,
  Stop: 4,
  PickupContainer: 8,
  DropContainer: 16,
} as const;

export type StopType = (typeof StopType)[keyof typeof StopType];

/**
 * @deprecated use EnumViewModel<StopType> instead.
 *
 * Maps a StopType enumeration value to its corresponding translation key.
 *
 * @param { StopType } stopType -
 *        The enumeration value representing the type of StopType.
 * @returns {string} The translation key corresponding to the given StopType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('StopType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromStopType(StopType.Pickup);
 * console.log(translationKey); // 'StopType.Pickup'
 * console.log(translocoService.translate(translationKey)) // "Translation of StopType.Pickup"
 *
 * // using in a template:
 * {{ translationKeyFromStopType(StopType.Pickup) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('StopType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromStopType(stopType: StopType) {
  switch (stopType) {
    case StopType.Undefined:
      return 'StopType.Undefined';
    case StopType.Pickup:
      return 'StopType.Pickup';
    case StopType.Delivery:
      return 'StopType.Delivery';
    case StopType.Stop:
      return 'StopType.Stop';
    case StopType.PickupContainer:
      return 'StopType.PickupContainer';
    case StopType.DropContainer:
      return 'StopType.DropContainer';
    default:
      return '';
  }
}

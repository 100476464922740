import { Injectable } from '@angular/core';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import {
  type PreLoadAddressCreateRequest,
  type PreLoadLaneBookingRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class SequencePreloadService extends BaseApiService {
  constructor() {
    super(`sequence.v1.preload`);
  }

  laneBooking(request: PreLoadLaneBookingRequest) {
    return this.http.post(`${this.url}lane-booking`, request, {
      context: HttpContextBuilder({
        sequence: true,
      }),
    });
  }

  addressCreate(request: PreLoadAddressCreateRequest) {
    return this.http.post(`${this.url}address-create`, request, {
      context: HttpContextBuilder({
        sequence: true,
      }),
    });
  }
}

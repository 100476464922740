/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = AmountOf  2 = All
 */
export const PalletExchangeType = {
  None: 0,
  AmountOf: 1,
  All: 2,
} as const;

export type PalletExchangeType =
  (typeof PalletExchangeType)[keyof typeof PalletExchangeType];

/**
 * @deprecated use EnumViewModel<PalletExchangeType> instead.
 *
 * Maps a PalletExchangeType enumeration value to its corresponding translation key.
 *
 * @param { PalletExchangeType } palletExchangeType -
 *        The enumeration value representing the type of PalletExchangeType.
 * @returns {string} The translation key corresponding to the given PalletExchangeType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('PalletExchangeType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromPalletExchangeType(PalletExchangeType.AmountOf);
 * console.log(translationKey); // 'PalletExchangeType.AmountOf'
 * console.log(translocoService.translate(translationKey)) // "Translation of PalletExchangeType.AmountOf"
 *
 * // using in a template:
 * {{ translationKeyFromPalletExchangeType(PalletExchangeType.AmountOf) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('PalletExchangeType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromPalletExchangeType(
  palletExchangeType: PalletExchangeType,
) {
  switch (palletExchangeType) {
    case PalletExchangeType.None:
      return 'PalletExchangeType.None';
    case PalletExchangeType.AmountOf:
      return 'PalletExchangeType.AmountOf';
    case PalletExchangeType.All:
      return 'PalletExchangeType.All';
    default:
      return '';
  }
}

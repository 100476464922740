/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NoPreference  1 = NotOnWeekends  2 = NotOnSaturdays  3 = NotOnSundays
 */
export const PickupDeliveryPreferenceType = {
  NoPreference: 0,
  NotOnWeekends: 1,
  NotOnSaturdays: 2,
  NotOnSundays: 3,
} as const;

export type PickupDeliveryPreferenceType =
  (typeof PickupDeliveryPreferenceType)[keyof typeof PickupDeliveryPreferenceType];

/**
 * @deprecated use EnumViewModel<PickupDeliveryPreferenceType> instead.
 *
 * Maps a PickupDeliveryPreferenceType enumeration value to its corresponding translation key.
 *
 * @param { PickupDeliveryPreferenceType } pickupDeliveryPreferenceType -
 *        The enumeration value representing the type of PickupDeliveryPreferenceType.
 * @returns {string} The translation key corresponding to the given PickupDeliveryPreferenceType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('PickupDeliveryPreferenceType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromPickupDeliveryPreferenceType(PickupDeliveryPreferenceType.NotOnWeekends);
 * console.log(translationKey); // 'PickupDeliveryPreferenceType.NotOnWeekends'
 * console.log(translocoService.translate(translationKey)) // "Translation of PickupDeliveryPreferenceType.NotOnWeekends"
 *
 * // using in a template:
 * {{ translationKeyFromPickupDeliveryPreferenceType(PickupDeliveryPreferenceType.NotOnWeekends) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('PickupDeliveryPreferenceType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromPickupDeliveryPreferenceType(
  pickupDeliveryPreferenceType: PickupDeliveryPreferenceType,
) {
  switch (pickupDeliveryPreferenceType) {
    case PickupDeliveryPreferenceType.NoPreference:
      return 'PickupDeliveryPreferenceType.NoPreference';
    case PickupDeliveryPreferenceType.NotOnWeekends:
      return 'PickupDeliveryPreferenceType.NotOnWeekends';
    case PickupDeliveryPreferenceType.NotOnSaturdays:
      return 'PickupDeliveryPreferenceType.NotOnSaturdays';
    case PickupDeliveryPreferenceType.NotOnSundays:
      return 'PickupDeliveryPreferenceType.NotOnSundays';
    default:
      return '';
  }
}

/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Metric  1 = ImperialUS
 */
export const MeasurementSystemType = {
  Metric: 0,
  ImperialUS: 1,
} as const;

export type MeasurementSystemType =
  (typeof MeasurementSystemType)[keyof typeof MeasurementSystemType];

/**
 * @deprecated use EnumViewModel<MeasurementSystemType> instead.
 *
 * Maps a MeasurementSystemType enumeration value to its corresponding translation key.
 *
 * @param { MeasurementSystemType } measurementSystemType -
 *        The enumeration value representing the type of MeasurementSystemType.
 * @returns {string} The translation key corresponding to the given MeasurementSystemType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('MeasurementSystemType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromMeasurementSystemType(MeasurementSystemType.ImperialUS);
 * console.log(translationKey); // 'MeasurementSystemType.ImperialUS'
 * console.log(translocoService.translate(translationKey)) // "Translation of MeasurementSystemType.ImperialUS"
 *
 * // using in a template:
 * {{ translationKeyFromMeasurementSystemType(MeasurementSystemType.ImperialUS) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('MeasurementSystemType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromMeasurementSystemType(
  measurementSystemType: MeasurementSystemType,
) {
  switch (measurementSystemType) {
    case MeasurementSystemType.Metric:
      return 'MeasurementSystemType.Metric';
    case MeasurementSystemType.ImperialUS:
      return 'MeasurementSystemType.ImperialUS';
    default:
      return '';
  }
}

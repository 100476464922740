/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = FreeOfVAT  2 = RegularVAT
 */
export const FCLVatType = {
  Undefined: 0,
  FreeOfVAT: 1,
  RegularVAT: 2,
} as const;

export type FCLVatType = (typeof FCLVatType)[keyof typeof FCLVatType];

/**
 * @deprecated use EnumViewModel<FCLVatType> instead.
 *
 * Maps a FCLVatType enumeration value to its corresponding translation key.
 *
 * @param { FCLVatType } fCLVatType -
 *        The enumeration value representing the type of FCLVatType.
 * @returns {string} The translation key corresponding to the given FCLVatType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('FCLVatType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromFCLVatType(FCLVatType.FreeOfVAT);
 * console.log(translationKey); // 'FCLVatType.FreeOfVAT'
 * console.log(translocoService.translate(translationKey)) // "Translation of FCLVatType.FreeOfVAT"
 *
 * // using in a template:
 * {{ translationKeyFromFCLVatType(FCLVatType.FreeOfVAT) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('FCLVatType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromFCLVatType(fCLVatType: FCLVatType) {
  switch (fCLVatType) {
    case FCLVatType.Undefined:
      return 'FCLVatType.Undefined';
    case FCLVatType.FreeOfVAT:
      return 'FCLVatType.FreeOfVAT';
    case FCLVatType.RegularVAT:
      return 'FCLVatType.RegularVAT';
    default:
      return '';
  }
}

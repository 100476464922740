/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = A_A  2 = A_B  3 = A_C  4 = A_D  5 = A_AB  6 = A_BC  7 = A_BCD
 */
export const TransitTimeType = {
  Undefined: 0,
  A_A: 1,
  A_B: 2,
  A_C: 3,
  A_D: 4,
  A_AB: 5,
  A_BC: 6,
  A_BCD: 7,
} as const;

export type TransitTimeType =
  (typeof TransitTimeType)[keyof typeof TransitTimeType];

/**
 * @deprecated use EnumViewModel<TransitTimeType> instead.
 *
 * Maps a TransitTimeType enumeration value to its corresponding translation key.
 *
 * @param { TransitTimeType } transitTimeType -
 *        The enumeration value representing the type of TransitTimeType.
 * @returns {string} The translation key corresponding to the given TransitTimeType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('TransitTimeType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromTransitTimeType(TransitTimeType.A_A);
 * console.log(translationKey); // 'TransitTimeType.A_A'
 * console.log(translocoService.translate(translationKey)) // "Translation of TransitTimeType.A_A"
 *
 * // using in a template:
 * {{ translationKeyFromTransitTimeType(TransitTimeType.A_A) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('TransitTimeType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromTransitTimeType(
  transitTimeType: TransitTimeType,
) {
  switch (transitTimeType) {
    case TransitTimeType.Undefined:
      return 'TransitTimeType.Undefined';
    case TransitTimeType.A_A:
      return 'TransitTimeType.A_A';
    case TransitTimeType.A_B:
      return 'TransitTimeType.A_B';
    case TransitTimeType.A_C:
      return 'TransitTimeType.A_C';
    case TransitTimeType.A_D:
      return 'TransitTimeType.A_D';
    case TransitTimeType.A_AB:
      return 'TransitTimeType.A_AB';
    case TransitTimeType.A_BC:
      return 'TransitTimeType.A_BC';
    case TransitTimeType.A_BCD:
      return 'TransitTimeType.A_BCD';
    default:
      return '';
  }
}

/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Sunday  1 = Monday  2 = Tuesday  3 = Wednesday  4 = Thursday  5 = Friday  6 = Saturday
 */
export const DayOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as const;

export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];

/**
 * @deprecated use EnumViewModel<DayOfWeek> instead.
 *
 * Maps a DayOfWeek enumeration value to its corresponding translation key.
 *
 * @param { DayOfWeek } dayOfWeek -
 *        The enumeration value representing the type of DayOfWeek.
 * @returns {string} The translation key corresponding to the given DayOfWeek.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('DayOfWeek')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromDayOfWeek(DayOfWeek.Monday);
 * console.log(translationKey); // 'DayOfWeek.Monday'
 * console.log(translocoService.translate(translationKey)) // "Translation of DayOfWeek.Monday"
 *
 * // using in a template:
 * {{ translationKeyFromDayOfWeek(DayOfWeek.Monday) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('DayOfWeek')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromDayOfWeek(dayOfWeek: DayOfWeek) {
  switch (dayOfWeek) {
    case DayOfWeek.Sunday:
      return 'DayOfWeek.Sunday';
    case DayOfWeek.Monday:
      return 'DayOfWeek.Monday';
    case DayOfWeek.Tuesday:
      return 'DayOfWeek.Tuesday';
    case DayOfWeek.Wednesday:
      return 'DayOfWeek.Wednesday';
    case DayOfWeek.Thursday:
      return 'DayOfWeek.Thursday';
    case DayOfWeek.Friday:
      return 'DayOfWeek.Friday';
    case DayOfWeek.Saturday:
      return 'DayOfWeek.Saturday';
    default:
      return '';
  }
}
